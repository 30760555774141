import React, { useState, useEffect } from "react";
import { useSearch } from "../../context/search";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SearchInput = () => {
  const [values, setValues] = useSearch();
  const navigate = useNavigate();
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [typedText, setTypedText] = useState("");
  const placeholders = ["search product  name , color  or  brand name"];

  useEffect(() => {
    const currentPlaceholder = placeholders[placeholderIndex];
    let currentIndex = 0;
    let isTypingForward = true;

    const interval = setInterval(() => {
      setTypedText((prevText) => {
        if (isTypingForward) {
          if (currentIndex === currentPlaceholder.length) {
            isTypingForward = false;
          } else {
            currentIndex++;
          }
        } else {
          if (currentIndex === 0) {
            isTypingForward = true;
          } else {
            currentIndex--;
          }
        }

        return currentPlaceholder.slice(0, currentIndex);
      });
    }, 100);

    return () => clearInterval(interval);
  }, [placeholderIndex]);

  useEffect(() => {
    const placeholderInterval = setInterval(() => {
      setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
    }, 3000); // Change the duration as needed

    return () => clearInterval(placeholderInterval);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.get(
        `/api/v1/product/search/${values.keyword}`
      );
      setValues({ ...values, results: data });
      navigate("/search");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container-fluid">
      <form
        className="d-flex flex-column flex-sm-row "
        role="search"
        onSubmit={handleSubmit}
      >
        <input
          className="form-control col-sm-2 col-md"
          type="search"
          aria-label="Search"
          value={values.keyword}
          onChange={(e) => setValues({ ...values, keyword: e.target.value })}
          placeholder={typedText}
        />
        <div className="d-flex  mx-1">
          <button
            className="btn btn-outline-primary mt-2 mt-sm-0"
            type="submit"
          >
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchInput;
