import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillGoogleCircle } from "react-icons/ai";

const date = new Date();
const currentYear = date.getFullYear();

const Footer = () => {
  return (
    <div className="container-fluid  p-1">
      {/* Footer */}
      <footer
        className="text-center text-lg-start text-black"
        // style={{
        //   background: "linear-gradient(to right,#00093c,#2d0b00",
        //   color: "#fff",
        //   borderTopLeftRadius: "60px",
        //   borderTopRightRadius: "60px",
        // }}
        style={{ backgroundColor: "#e3dae7", color: "black" }}
      >
        <section className="p-4">
          <div className="container-fluid text-center text-md-start">
            {/* Grid row */}
            <div className="row">
              {/* Grid column */}
              <div className="col-md-3 col-lg-4 col-xl-3 m-50 ">
                {/* Content */}
                <h6 className="text-uppercase fw-bold text-black">
                  MANASVI E-COMMERCE
                </h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto text-black"
                  style={{ backgroundColor: "#7c4dff", height: 2 }}
                />
                <p className="text-uppercase  text-black">
                We are MNC companies with good work environment and supportive
                  staff
                </p>
              </div>
              {/* Grid column */}
              {/* Grid column */}
              <div className="col-md-2 text-center col-lg-2 col-xl-2 mx-auto mb-4">
                {/* Links */}
                <h6 className="text-uppercase fw-bold text-black">Links</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{ width: 60, backgroundColor: "#7c4dff", height: 2 }}
                />
                <p>
                  <Link to="/" className="text-black text-decoration-none">
                    Home
                  </Link>
                </p>
                <p>
                  <Link to="/about" className="text-black text-decoration-none">
                    About
                  </Link>
                </p>
                <p>
                  <Link
                    to="/contact"
                    className="text-black text-decoration-none"
                  >
                    Contact
                  </Link>
                </p>

                <p>
                  <Link
                    to="/policy"
                    className="text-black text-decoration-none"
                  >
                    Privacy Policy
                  </Link>
                </p>
              </div>
              {/* Grid column */}
              {/* Grid column */}
              <div className="col-md-2 text-center col-lg-2 col-xl-2 mx-auto mb-4">
                {/* Links */}
                <h6 className="text-uppercase fw-bold text-black">
                  Social links
                </h6>
              
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{ width: 120, backgroundColor: "#7c4dff", height: 2 }}
                />
                

                <div className="text-center">
                  <a
                    href="https://www.linkedin.com/in/manasvi-technologies-7aa426262/"
                    className="text-black me-4"
                  >
                    <AiFillLinkedin />
                  </a>
                  <a
                    href="https://www.manasvitech.in/"
                    className="text-black me-4"
                  >
                    <AiFillGoogleCircle />
                  </a>
                  <a
                    href="https://www.instagram.com/manasvi.technologies/"
                    className="text-black me-4"
                  >
                    <AiOutlineInstagram />
                  </a>
                </div>

                <p className="text-center" style={{ margin: "10px" }}>
                  <Link
                    to="/contact"
                    className="text-black text-decoration-none"
                  >
                    Help ?
                  </Link>
                </p>
              </div>
              {/* Grid column */}
              {/* Grid column */}
              <div className="col-md-2 text-center col-lg-2 col-xl-2 mx-auto mb-4">
                {/* Links */}
                <h6 className="text-uppercase fw-bold text-black">
                  Contact
                 
                </h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{ width:"90px", backgroundColor: "#7c4dff", height: 2 }}
                />
                <p className="text-black">
                  {" "}
                 Corporate Office:111B,80 Feet Road, Old Ashoka Garden Bhopal(462023)
                </p>
                <a
                  href="mailto:manasvitech01@gmail.com"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  manasvitech01@gmail
                </a>
                <br />
                <a
                  href="https://www.manasvitech.in/"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  manasvitech.in
                </a>
                <br />
                <a
                  className="text-black"
                  style={{ textDecoration: "none", color: "white" }}
                  href="tel:8319955741"
                >
                  {" "}
                  + 91 8319955741
                </a>
              </div>
              {/* Grid column */}

              <hr />
              <div className="text-center text-black fs-10">
                © {currentYear} Copyright : &nbsp;
                <a
                  className="text-black text-decoration-none"
                  href="https://www.manasvitech.in/"
                >
                  manasvi technologies (opc) pvt ltd
                </a>
              </div>
            </div>
            {/* Grid row */}
          </div>
        </section>

        {/* Section: Links  */}
        {/* Copyright */}

        {/* Copyright */}
      </footer>
    </div>
  );
};
export default Footer;
