import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import useBaseCategory from "../../hooks/useBaseCategory";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Layout.css";

const HeaderCategory = () => {
  const baseCategories = useBaseCategory();
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async (categoryId) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `/api/v1/category/get-category/${categoryId}`
      );
      setCategories(data.data || []);
    } catch (error) {
      console.error("Error fetching category details:", error);
    } finally {
      setLoading(false);
    }
  };
  const paddingTopValue =
    window.innerWidth > 319 && window.innerWidth < 767 ? "5px" : "25px";

  const fetchSubcategories = async (subcategoryId) => {
    try {
      const { data } = await axios.get(
        `/api/v1/subcategory/get-sub-category/${subcategoryId}`
      );
      setSubCategories(data?.data || []);
    } catch (error) {
      console.log("Error fetching subcategories:", error);
    }
  };

  const handleCategoryClick = async (baseId) => {
    fetchData(baseId);
    // Reset subcategories when clicking on a category to prevent showing previous subcategories
    setSubCategories([]);
  };

  const handleSubcategoryClick = async (catId) => {
    fetchSubcategories(catId);
  };

  useEffect(() => {
    if (baseCategories && baseCategories.length > 0) {
      fetchData(baseCategories[0]._id);
    }
  }, [baseCategories]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: baseCategories.length > 10 ? 10 : baseCategories.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Breakpoint for mobile view
        settings: {
          slidesToShow: baseCategories.length > 3 ? 3 : baseCategories.length,
          slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 992, // Breakpoint for tablet view
        settings: {
          slidesToShow: baseCategories.length > 6 ? 6 : baseCategories.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200, // Breakpoint for desktop view
        settings: {
          slidesToShow: baseCategories.length > 10 ? 10 : baseCategories.length,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="mobile-container">
        <Slider {...settings}>
          {baseCategories?.map((b, index) => (
            <div key={b.slug} className="slick-slide">
              <div
                style={{ backgroundColor: "#FFFFFF" }}
                className="container-fluid pt-1 mt-1"
              >
                <ul className="navbar-nav justify-content-center mt-5 pt-2 gap-3 d-flex flex-row text-bottom flex-wrap">
                  <li
                    key={b.slug}
                    className={`nav-item ${index !== 0 ? "mr-5" : ""} ${
                      index % 1 === 1 ? "mt-3" : ""
                    } ${baseCategories.length > 2 ? "col-lg-4" : "col-lg-6"}`}
                    style={{ width: "150px" }}
                  >
                    <div className="dropdown">
                      {/* <Link
                        className="nav-link dropdown-toggle text-center"
                        to={`/bcategory/${b._id}`}
                        style={{
                          color: "#2d3436",
                          paddingLeft: "10px",
                          paddingTop: "35px",
                          gap: "12px",
                        }}
                        onClick={() => handleCategoryClick(b._id)}
                      >
                        <img
                          src={`/api/v1/bcategory/single-photo/${b._id}`}
                          height="50px"
                          alt=""
                        />
                        <br />
                        <b>{b.name}</b>
                      </Link> */}
                      <Link
                        className="nav-link text-center"
                        to={`/bcategory/${b._id}`}
                        style={{
                          color: "#2d3436",
                          paddingLeft: "10px",
                          // paddingTop: "5px",
                          paddingTop: paddingTopValue,
                          gap: "12px",
                          display: "block", // Added to make the link a block-level element
                        }}
                        onClick={() => handleCategoryClick(b._id)}
                      >
                        <img
                          src={`/api/v1/bcategory/single-photo/${b._id}`}
                          height="50px"
                          alt=""
                          style={{ display: "block", margin: "0 auto" }} // Center the image horizontally
                        />
                        <br />
                        <b style={{ display: "block", margin: "0 auto" }}>
                          {b.name}
                        </b>
                      </Link>

                      <ul className="dropdown-menu ml-5">
                        {loading ? (
                          <li>Loading...</li>
                        ) : Array.isArray(categories) &&
                          categories.length > 0 ? (
                          categories.map((category) => (
                            <li
                              key={category._id}
                              style={{ position: "relative" }}
                            >
                              <div className="dropdown">
                                <Link
                                  className="dropdown-item text-center"
                                  to={`/category/${category._id}`}
                                  onClick={() =>
                                    handleSubcategoryClick(category._id)
                                  }
                                >
                                  {category.name}
                                </Link>
                                {subcategories.length > 0 &&
                                  subcategories.some(
                                    (subcategory) =>
                                      subcategory.parentCategoryId ===
                                      category._id
                                  ) && (
                                    <ul className="dropdown-menu ml-5">
                                      {subcategories.map((subcategory) => (
                                        <li key={subcategory._id}>
                                          <Link
                                            className="dropdown-item"
                                            to={`/subcategory/${subcategory._id}`}
                                          >
                                            {subcategory.name}
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                              </div>
                            </li>
                          ))
                        ) : (
                          <li>No categories available</li>
                        )}
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default HeaderCategory;
