import React, { useEffect, useState } from "react";
import Layout from "./../../../components/Layout/Layout";
import AdminMenu from "./../../../components/Layout/AdminMenu";
import toast from "react-hot-toast";
import axios from "axios";
import Modal from "antd/es/modal";

const CreateBaseCategory = () => {
  const [bcategories, setBCategories] = useState([]);
  const [bname, setBName] = useState("");
  const [bvisible, setBVisible] = useState(false);
  const [bselected, setBSelected] = useState(null);
  const [bupdatedName, setBUpdatedName] = useState("");
  const [photo, setPhoto] = useState(null);
  const [uphoto, setUphoto] = useState(null);

  // handle Form
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!bname) {
        toast.error("please enter base category name ");
        return;
      }
      if (!photo) {
        toast.error("please upload photo ");
        return;
      }
      const formData = new FormData();
      formData.append("name", bname);
      formData.append("image", photo);

      const { data } = await axios.post(
        "/api/v1/bcategory/create-base-category",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      if (data?.success) {
        toast.success(`${bname} is created`);
        setBName("");
        setPhoto(null);
        getAllBCategory();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("something went wrong in input form");
    }
  };

  //get all cat
  const getAllBCategory = async () => {
    try {
      const { data } = await axios.get("/api/v1/bcategory/all");
      if (data?.success) {
        setBCategories(data?.data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting category");
    }
  };

  useEffect(() => {
    getAllBCategory();
  }, []);

  //update category
  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("name", bupdatedName);
      formData.append("image", uphoto);

      const { data } = await axios.put(
        `/api/v1/bcategory/update-base-category/${bselected._id}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      if (data?.success) {
        
 

        toast.success(`${bupdatedName} is updated`);
        getAllBCategory();
        window.location.reload();
        setBSelected(null);
        setBUpdatedName("");
        setUphoto(null);
        setBVisible(false);
      
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in updating category");
    }
  };

  //delete category
  const handleDelete = async (pId) => {
    try {  
     let ans= prompt("Are You sure for  delete ,Press y/n to continue")
     if(ans==='n'){
      return
     }if(ans==='y'){
      const { data } = await axios.delete(
        `/api/v1/bcategory/deleteByid/${pId}`
      );
      if (data.success) {
        toast.success(`Category is deleted`);
        getAllBCategory();
      } else {
        toast.error(data.message);
      }
    } 
     }
      catch (error) {
      toast.error("Something went wrong");
    }
  };

  // handle image change
  const handleImageChange = (e) => {
    setPhoto(e.target.files[0]);
  };

  const handleImageChangeUpdate = (e) => {
    setUphoto(e.target.files[0]);
  };

  return (
    <>
      <Layout title={"Dashboard - Create Category"}>
        <div className="container mt-4">
          <div className="row dashboard">
            <div className="col-md-3">
              <AdminMenu />
            </div>
            <div className="col-md-9">
              <h1>Manage Base Category</h1>
              <form onSubmit={handleSubmit}>
                <div className="p-3 w-50">
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter new Base Category"
                      value={bname}
                      onChange={(e) => setBName(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="block w-full p-4 border-dashed border-2 border-gray-300 cursor-pointer">
                      {photo ? photo.name : "Upload Photo"}
                      <input
                        type="file"
                        name="photo"
                        accept="image/*"
                        onChange={handleImageChange}
                        hidden
                      />
                    </label>
                  </div>

                  <div className="mb-3">
                    {photo && (
                      <div className="text-center">
                        <img
                          src={URL.createObjectURL(photo)}
                          alt="xyz_photo"
                          className="block mx-auto max-h-40"
                        />
                      </div>
                    )}
                  </div>

                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>

              <div className="w-100">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bcategories?.map((c) => (
                      <tr key={c._id}>
                        <td>{c.name}</td>
                        <td>
                          <button
                            className="btn btn-primary ms-2"
                            onClick={() => {
                              setBVisible(true);
                              setBUpdatedName(c.name);
                              setBSelected(c);
                            }}
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-danger ms-2"
                            onClick={() => {
                              handleDelete(c._id);
                            }}
                          >
                            Delete
                          </button>
                          <td>
                            <img
                              src={`/api/v1/bcategory/single-photo/${c._id}`}
                              className="card-img-top"
                              width="50px"
                              height="50px"
                              alt={c.name}
                            />
                          </td>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Modal
                onCancel={() => setBVisible(false)}
                footer={null}
                visible={bvisible}
              >
                <div className="p-3 w-50">
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Enter updated name"
                    value={bupdatedName}
                    onChange={(e) => setBUpdatedName(e.target.value)}
                  />
                  <div className="mb-3">
                    <label className="block w-full p-4 border-dashed border-2 border-gray-300 cursor-pointer">
                      {uphoto ? uphoto.name : "Upload New Photo"}
                      <input
                        type="file"
                        name="photo"
                        accept="image/*"
                        onChange={handleImageChangeUpdate}
                        hidden
                      />
                    </label>
                  </div>
                  {uphoto && (
                    <div className="text-center">
                      <img
                        src={URL.createObjectURL(uphoto)}
                        alt="Updated Category Photo"
                        className="block mx-auto max-h-40"
                      />
                    </div>
                  )}
                  <button
                    className="btn btn-primary mt-3"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CreateBaseCategory;
