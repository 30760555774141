import React from "react";
import Layout from "./../components/Layout/Layout";

const Policy = () => {
  return (
    <Layout title={"Privacy Policy"}>
      <div className="row contactus mb-5 mt-3 m-1 p-1 ">
        <div className="col-md-6 text-center">
          <img
            src="/images/privacy.jpg"
            alt="contactus"
            style={{ width: "100%" }}
          />
        </div>
        <div className="col-md-5 text-center">
          <p  className=""> 
            It's important to ensure that the privacy policy is easily
            accessible on the website, typically in the footer or during the
            account creation/checkout process. Furthermore, the policy should be
            written in clear and understandable language for users.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Policy;
