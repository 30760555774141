export const Discount = [
    {
      _id: 0,
      name: "0% - 20%",
      array: [0, 20],
    },
    {
      _id: 1,
      name: "20% - 40%",
      array: [20, 40],
    },
    {
      _id: 2,
      name: "40% - 60%",
      array: [40, 60],
    },
    {
      _id: 3,
      name: "60% - 80%",
      array: [60, 80],
    },
    {
      _id: 4,
      name: "80% or more",
      array: [80, 99],
    },
   
  ];