import React, { useState, useEffect } from "react";
import UserMenu from "../../components/Layout/UserMenu";
import Layout from "./../../components/Layout/Layout";
import { useAuth } from "../../context/auth";
import toast from "react-hot-toast";
import axios from "axios";
const Profile = () => {
  //context
  const [auth, setAuth] = useAuth();
  //state
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [locality, setLocality] = useState("");
  const [pincode, setPincode] = useState("");
  const [address, setAddress] = useState("");
  const [city_district_town, setCity_district_town] = useState("");
  const [landmark, setLandmark] = useState("");
  const [alternate_phone, setAlternate_phone] = useState("");
  const [shipping_address, setsa] = useState("");
  //get user data
  useEffect(() => {
    const {
      name,
      email,
      phone,
      locality,
      pincode,
      address,
      city_district_town,
      landmark,
      alternate_phone,
      shipping_address,
    } = auth?.user;
    setName(name);
    setEmail(email);
    setPhone(phone);
    setLocality(locality);
    setPincode(pincode);
    setAddress(address);
    setCity_district_town(city_district_town);
    setLandmark(landmark);
    setAlternate_phone(alternate_phone);
    setsa(shipping_address);
  }, [auth?.user]);

  // form function
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if(phone.length!==10){
        toast.error("mobile number must be 10 digit only ")
        return;
      }

      if(alternate_phone.length!==10){
        toast.error(" alternate mobile number must be 10 digit only ")
        return;
      }


      if(pincode.length!==6){
        toast.error("pincode must be 6 digit only ")
        return;
      }
      const { data } = await axios.put("/api/v1/auth/profile", {
        name,
        email,
        password,
        phone,
        locality,
        pincode,
        address,
        city_district_town,
        landmark,
        alternate_phone,
        shipping_address,
      });
      console.log(data, "this is data for updation");
      if (data?.errro) {
        toast.error(data?.error);
      } else {
        setAuth({ ...auth, user: data?.updatedUser });
        let ls = localStorage.getItem("auth");
        ls = JSON.parse(ls);
        ls.user = data.updatedUser;
        localStorage.setItem("auth", JSON.stringify(ls));
        toast.success("Profile Updated Successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  return (
    <Layout title={"Your Profile"}>
      <div className="container ">
        <div className="row dashboard">
          <div className="col-md-3">
            <UserMenu />
          </div>
          <div className="col-md-8">
            <div className="form-container" style={{ marginTop: "30px" }}>
              <form onSubmit={handleSubmit}>
                <h4 className="title">USER PROFILE</h4>
                <div className="mb-3">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Enter Your Name"
                    autoFocus
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Enter Your Email "
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Enter Your Password"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Enter Your Phone"
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    value={address}
                    onChange={(e) => {
                      const newAddress = e.target.value.slice(0, 100); // Limit input to 100 characters
                      setAddress(newAddress);
                    }}
                    className="form-control"
                    id="exampleFormControlTextarea1" // Change the id
                    placeholder="Address"
                    required
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    value={shipping_address}
                    onChange={(e) => {
                      const newAddress = e.target.value.slice(0, 150); // Limit input to 100 characters
                      setsa(newAddress);
                    }}
                    className="form-control"
                    id="exampleFormControlTextarea1" // Change the id
                    placeholder="Shipping Address"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    value={locality}
                    onChange={(e) => setLocality(e.target.value)}
                    className="form-control"
                    placeholder="Enter Your Locality "
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="number"
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                    className="form-control"
                    placeholder="Enter Your Pincode "
                    required
                  />
                </div>

                <div className="mb-3">
                  <input
                    type="text"
                    value={city_district_town}
                    onChange={(e) => setCity_district_town(e.target.value)}
                    className="form-control"
                    placeholder="Enter Your setCity / district / town "
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    value={landmark}
                    onChange={(e) => setLandmark(e.target.value)}
                    className="form-control"
                    placeholder="Enter Your landmark "
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="number"
                    value={alternate_phone}
                    onChange={(e) => setAlternate_phone(e.target.value)}
                    className="form-control"
                    placeholder="Enter Your Alternate Phone "
                    required
                  />
                </div>

                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    backgroundColor: "#ffa502",
                    color: "#000",
                    borderRadius: "80px",
                    fontWeight: "bold",
                  }}
                >
                  UPDATE
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
