import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox, Radio } from "antd";
import { Prices } from "../components/Prices.js";
import { Discount } from "../components/Discount.js";
import { useCart } from "../context/cart";
import axios from "axios";
import toast from "react-hot-toast";
import Layout from "./../components/Layout/Layout";
import { AiOutlineReload } from "react-icons/ai";
import "../styles/Homepage.css";
import HeaderCategory from "../components/Layout/HeaderCategory.js";

const HomePage = () => {
  const navigate = useNavigate();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // eslint-disable-next-line
  const [cart, setCart] = useCart();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubCategories] = useState([]);
  const [checked, setChecked] = useState([]);
  const [radio, setRadio] = useState([]);
  const [radios, setRadios] = useState([]);
  const [sub, setSub] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  //get all cat
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get("/api/v1/category/get-category");
      if (data?.success) {
        setCategories(data?.category);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllSubCategory = async () => {
    try {
      const { data } = await axios.get("/api/v1/subcategory/get-sub-category");
      if (data?.success) {
        console.log(data);
        setSubCategories(data?.category);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something wwent wrong in getting catgeory");
    }
  };
  useEffect(() => {
    getAllCategory();
    getTotal();
    getAllSubCategory();
  }, []);
  //get products
  const getAllProducts = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/api/v1/product/product-list/${page}`);
      setLoading(false);
      setProducts(data.products);
      console.log(data.products, "this is products");
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  //getTOtal COunt
  const getTotal = async () => {
    try {
      const { data } = await axios.get("/api/v1/product/product-count");
      setTotal(data?.total);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (page === 1) return;
    loadMore();
    // eslint-disable-next-line
  }, [page]);
  const loadMore = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/api/v1/product/product-list/${page}`);
      setLoading(false);
      setProducts([...products, ...data?.products]);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  // filter y cat
  const handleFilter = (value, id) => {
    let all = [...checked];
    if (value) {
      all.push(id);
    } else {
      all = all.filter((c) => c !== id);
    }
    setChecked(all);
  };
  const handleSubFilter = (value, id) => {
    let all = [...checked];
    if (value) {
      all.push(id);
    } else {
      all = all.filter((c) => c !== id);
    }
    setSub(all);
  };
  useEffect(() => {
    if (!checked.length || !radio.length || !radios.length || !sub.length)
      getAllProducts();
    // eslint-disable-next-line
  }, [checked.length, radio.length, radios.length, sub.length]);
  useEffect(() => {
    if (checked.length || radio.length || radios.length || sub.length)
      filterProduct();
    // eslint-disable-next-line
  }, [checked, radio, radios, sub]);
  //get filterd product
  const filterProduct = async () => {
    try {
      const { data } = await axios.post("/api/v1/product/product-filters", {
        checked,
        radio,
        radios,
        sub,
      });
      setProducts(data?.products);
    } catch (error) {
      console.log(error);
    }
  };
  //add to cart
  const addToCart = async (product) => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    const existingProductIndex = storedCart.findIndex(
      (item) => item._id === product._id
    );
    if (existingProductIndex !== -1) {
      storedCart[existingProductIndex].customQuantity += 1;
    } else {
      storedCart.push({ ...product, customQuantity: 1 });
    }
    localStorage.setItem("cart", JSON.stringify(storedCart));
    setCart(storedCart);
    toast.success("Item Added to cart");
  };
  const isMobileDevice = () => {
    return window.innerWidth <= 769; // Adjust this threshold as needed
  };

  // console.log(products,"products");
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Layout title={"All Products - Best offers "}>
      <HeaderCategory />

      {/* this is for mobile view */}
      <div
        className={`carousel-container ${
          window.innerWidth > 319 && window.innerWidth < 767
            ? "show-carousel"
            : "hide-carousel"
        }`}
      >
        <div
          id="carouselExampleFade"
          className={`carousel slide carousel-fade mt-28 w-full p-3 pt-10 `}
          data-bs-ride="carousel"
        >
          <div
            style={{ borderRadius: "10px" }}
            className="carousel-inner mt-28"
          >
            <div className="carousel-item active" data-bs-interval="1000">
              <img
                src="images/d1.jpg"
                width={"20%"}
                height={"100px"}
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item" data-bs-interval="1000">
              <img
                src="images/d2.jpg"
                width={"20%"}
                height={"100px"}
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item" data-bs-interval="1000">
              <img
                src="images/d3.jpg"
                width={"20%"}
                height={"100px"}
                className="d-block w-100"
                alt="..."
              />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <div
        className={`carousel-container ${
          window.innerWidth > 768 ? "show-carousel" : "hide-carousel"
        }`}
      >
        <div
          id="carouselExampleFade"
          className={`carousel slide carousel-fade mt-28 w-full p-3 pt-10 `}
          data-bs-ride="carousel"
        >
          <div
            style={{ borderRadius: "10px" }}
            className="carousel-inner mt-28"
          >
            <div className="carousel-item active" data-bs-interval="1000">
              <img
                src="images/d1.jpg"
                width={"100%"}
                height={"400px"}
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item" data-bs-interval="1000">
              <img
                src="images/d2.jpg"
                width={"100%"}
                height={"400px"}
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item" data-bs-interval="1500">
              <img
                src="images/d3.jpg"
                width={"100%"}
                height={"400px"}
                className="d-block w-100"
                alt="..."
              />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div className=" md-3 home-page">
        {/* <div
          className={`col-md-3 filtersbtn ${isFiltersOpen ? "open" : "closed"}`}
        > */}
        {/* <button
            className={`btn btn-primary d-block d-md-none ${
              isFiltersOpen ? "btn-success" : "btn-primary"
            }`}
            onClick={() => setIsFiltersOpen(!isFiltersOpen)}
          >
            {isFiltersOpen ? "Sort By Filters" : "Close Filters"}
          </button> */}

        <div
          className={`col-md-3 filter filters ${
            isMobileDevice ? "showfilter" : "hidefilter"
          }`}
        >
          <h6 className="">Filter By Category</h6>
          <div className="d-flex flex-column ">
            {categories?.map((c) => (
              <Checkbox
                className=""
                key={c._id}
                onChange={(e) => handleFilter(e.target.checked, c._id)}
              >
                {c.name}
              </Checkbox>
            ))}
          </div>
          <h6 className="">Filter By SubCategory</h6>
          <div className="d-flex flex-column">
            {subcategories?.map((c) => (
              <Checkbox
                key={c._id}
                className=""
                onChange={(e) => handleSubFilter(e.target.checked, c._id)}
              >
                {c.name}
              </Checkbox>
            ))}
          </div>
          <hr />
          <h6 className=" mt-4">Filter By Price</h6>
          <div className="d-flex flex-column ">
            <Radio.Group onChange={(e) => setRadio(e.target.value)}>
              {Prices?.map((p) => (
                <div key={p._id}>
                  <Radio className="" value={p.array}>
                    {p.name}
                  </Radio>
                </div>
              ))}
            </Radio.Group>
          </div>

          <h6 className=" mt-4">Filter By Discount</h6>
          <div className="d-flex flex-column">
            <Radio.Group onChange={(e) => setRadios(e.target.value)}>
              {Discount?.map((p) => (
                <div key={p._id}>
                  <Radio className="" value={p.array}>
                    {p.name}
                  </Radio>
                </div>
              ))}
            </Radio.Group>
          </div>
          <div className="d-flex flex-column">
            <button
              className="btn btn-success mt-4"
              onClick={() => window.location.reload()}
            >
              RESET FILTERS
            </button>
          </div>
        </div>
        {/* </div> */}
        {/* ///////////////////filter code for mobile view */}
        {/* Mobile View Filters */}

        {/* ///////////// */}
        <div className="col-md-10 text-center fish">
          <h1 className="text-center">All Products</h1>
          <div
            style={{ boxSizing: "none" }}
            className=" mediaquery allproduct w- myrow row-cols-1 p-3 row-cols-md-3 row-cols-lg-5"
          >
            {products?.map((p) => (
              <div className="col-md-3 card m-1" key={p._id}>
                <div className="d-flex flex-row">
                  {/* <h5 className="ms-auto text-secondary px-1">{p.color}</h5> */}
                </div>
                {/* <a href={`/product/${p.slug}`}>
                  <img
                    src={`/api/v1/product/product-photo/${p._id}`}
                    className={`card-img-top ${
                      windowWidth <= 767 ? "h-25" : ""
                    }`}
                    width="100%"
                    height="50px"
                    //alt={p.name}
                  />
                </a> */}
                <div>
                  {/* Discount paragraph */}
                  <p
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      borderTopLeftRadius: "30px",
                      borderBottomRightRadius: "30px",
                    }}
                    className={`bg-success btnd ${
                      windowWidth <= 767 ? "w-25" : "w-25"
                    } pt-2 pb-2 text-white text-center`}
                  >
                    <span style={{ width: "5px", fontSize: "15px" }}>
                      {p.discount}% off
                    </span>
                  </p>

                  {/* Image */}
                  <a href={`/product/${p.slug}`}>
                    <img
                      src={`/api/v1/product/product-photo/${p._id}`}
                      className={`card-img-top ${
                        windowWidth <= 767 ? "h-25 " : ""
                      }`}
                      width="50px"
                      height="50px"
                      alt={p.name}
                    />
                  </a>
                </div>
                <div class="container">
                  <div class="row">
                    {/* <div class="col">
                      <span style={{display:"inline-block"}} >{p.name}</span>
                    </div> */}
                    <div class="col">
                      <span style={{ display: "inline-block" }}>
                        {p.name.split(" ").slice(0, 3).join(" ")}
                        {p.name.split(" ").length > 3 ? " ..." : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {/* <h5 className="card-title">{p.name}</h5> */}

                  {/* <div className="card-name-price">
                    <p>{p.feature}</p>
                  </div> */}
                  <div className="card-name-price">
                    <h6 className="card-title card-price">
                      {" "}
                      Price:&nbsp;
                      {Math.round(
                        p.price - (p.price * p.discount) / 100
                      ).toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </h6>
                    <span className="mrp text-danger">
                      MRP&nbsp;
                      <del>
                        {p.price.toLocaleString("en-IN", {
                          style: "currency",
                          currency: "INR",
                        })}
                      </del>
                    </span>
                  </div>
                  <div className="card-name-price">
                    <button
                      className="btn btn-info ms-1"
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        borderRadius: "80px",
                      }}
                      onClick={() => navigate(`/product/${p.slug}`)}
                    >
                      More Details
                    </button>
                    {/* <button
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        borderRadius: "80px",
                        
                      }}
                      className="btn btn-success ms-1 w-full"
                      onClick={() => {
                        addToCart(p);
                      }}
                      id={p._id}
                    >
                      ADD TO CART
                    </button> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="">
            {products && products.length < total && (
              <button
                className="btn loadmore"
                onClick={(e) => {
                  e.preventDefault();
                  setPage(page + 1);
                }}
              >
                {loading ? (
                  <div className="text-center">
                    <div className="loading-spinner"></div>
                    <p className="text-red-500">Loading...</p>
                  </div>
                ) : (
                  <>
                    {" "}
                    Loadmore <AiOutlineReload />
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default HomePage;
